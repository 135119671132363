import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import Pagination from "../components/Pagination";
import EducationTile from "../components/EducationTile";

const EducationPage = ({ pageContext, data }) => {
   const seo = pageContext.seo;
   const listItems = data.allWpEducation.nodes;
   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="education-page">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="education__list">
                  <div className="row">
                     {listItems.map((item, index) => (
                        <div className="col-xl-4 col-md-6" key={index}>
                           <EducationTile
                              title={item.title}
                              thumbnail={
                                 item.featuredImage
                                    ? item.featuredImage.node.sourceUrl
                                    : require("assets/images/education-placeholder.png")
                                         .default
                              }
                              link={item.uri}
                              date={item.date}
                           />
                        </div>
                     ))}
                  </div>
               </div>
               <Pagination {...pageContext} />
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   query ($limit: Int!, $skip: Int!) {
      allWpEducation(
         limit: $limit
         skip: $skip
         sort: { fields: date, order: DESC }
      ) {
         nodes {
            title
            uri
            id
            slug
            date(formatString: "DD.MM.y")
            featuredImage {
               node {
                  sourceUrl
                  altText
               }
            }
         }
      }
   }
`;

export default EducationPage;
