import "./pagination.scss";

import React, { useState } from "react";
import { Link, navigate } from "gatsby";

const Pagination = ({ currentPage = 1, numPages = 1, uri }) => {
   const [pageInput, setPageInput] = useState("");

   const isFirst = currentPage === 1;
   const isLast = currentPage === numPages;
   const prevPage =
      currentPage - 1 === 1 ? uri : `${uri}${(currentPage - 1).toString()}`;
   const nextPage = (currentPage + 1).toString();
   const current = Number(currentPage);

   // If there are more than 6 pages, create intelligent pagination
   const paginationResolver = () => {
      // First page
      if (numPages >= 6 && currentPage === 1) {
         return [currentPage - 1, currentPage, currentPage + 1].map((i) => (
            <Link
               key={`pagination-number${i + 1}`}
               to={`${uri}${i === 0 ? "" : i + 1}`}
               className={`${
                  i === current - 1
                     ? "pagination__number pagination__number--current"
                     : "pagination__number"
               }`}
            >
               {i + 1}
            </Link>
         ));
      }
      // Second page
      else if (numPages >= 6 && currentPage === 2) {
         return [
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
         ].map((i) => (
            <Link
               key={`pagination-number${i + 1}`}
               to={`${uri}${i === 0 ? "" : i + 1}`}
               className={`${
                  i === current - 1
                     ? "pagination__number pagination__number--current"
                     : "pagination__number"
               }`}
            >
               {i + 1}
            </Link>
         ));
      }
      // Third page
      else if (numPages >= 6 && currentPage === 3) {
         return [
            currentPage - 3,
            currentPage - 2,
            currentPage - 1,
            currentPage,
         ].map((i) => (
            <Link
               key={`pagination-number${i + 1}`}
               to={`${uri}${i === 0 ? "" : i + 1}`}
               className={`${
                  i === current - 1
                     ? "pagination__number pagination__number--current"
                     : "pagination__number"
               }`}
            >
               {i + 1}
            </Link>
         ));
      }
      // Third from the end
      else if (numPages >= 6 && currentPage === numPages - 2) {
         return [
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
         ].map((i) => (
            <Link
               key={`pagination-number${i + 1}`}
               to={`${uri}${i === 0 ? "" : i + 1}`}
               className={`${
                  i === current - 1
                     ? "pagination__number pagination__number--current"
                     : "pagination__number"
               }`}
            >
               {i + 1}
            </Link>
         ));
      }
      // Second from the end
      else if (numPages >= 6 && currentPage === numPages - 1) {
         return [
            currentPage - 3,
            currentPage - 2,
            currentPage - 1,
            currentPage,
         ].map((i) => (
            <Link
               key={`pagination-number${i + 1}`}
               to={`${uri}${i === 0 ? "" : i + 1}`}
               className={`${
                  i === current - 1
                     ? "pagination__number pagination__number--current"
                     : "pagination__number"
               }`}
            >
               {i + 1}
            </Link>
         ));
      }
      // Last
      else if (numPages >= 6 && currentPage === numPages) {
         return [currentPage - 3, currentPage - 2, currentPage - 1].map((i) => (
            <Link
               key={`pagination-number${i + 1}`}
               to={`${uri}${i === 0 ? "" : i + 1}`}
               className={`${
                  i === current - 1
                     ? "pagination__number pagination__number--current"
                     : "pagination__number"
               }`}
            >
               {i + 1}
            </Link>
         ));
      }
      // All pages in between
      else {
         return [currentPage - 2, currentPage - 1, currentPage].map((i) => (
            <Link
               key={`pagination-number${i + 1}`}
               to={`${uri}${i === 0 ? "" : i + 1}`}
               className={`${
                  i === current - 1
                     ? "pagination__number pagination__number--current"
                     : "pagination__number"
               }`}
            >
               {i + 1}
            </Link>
         ));
      }
   };
   return (
      <div className="pagination">
         {/* Move to first page, if not already first page */}
         {!isFirst && (
            <Link
               to={prevPage}
               className="pagination__arrow pagination__arrow--prev"
            ></Link>
         )}

         {/* Adds first page pointer, when in between */}
         {numPages >= 6 &&
            currentPage !== 1 &&
            currentPage !== 2 &&
            currentPage !== 3 &&
            currentPage && (
               <>
                  <Link
                     key={`pagination-number-1`}
                     to={uri}
                     className="pagination__number"
                  >
                     1
                  </Link>
                  <div className="pagination__spacer">
                     ...
                     <form
                        onSubmit={(e) => {
                           e.preventDefault();
                           navigate(`${uri}${pageInput}`);
                        }}
                     >
                        <input
                           className="pagination__input"
                           name="page-number"
                           id="pageNumber"
                           type="number"
                           placeholder="..."
                           value={pageInput}
                           onChange={(e) => {
                              setPageInput(e.target.value);
                           }}
                           min={1}
                           max={numPages}
                        />
                     </form>
                  </div>
               </>
            )}

         {/* If there are 6 pages or more, create intelligent pagination */}
         {numPages >= 6 && paginationResolver()}

         {/* If there are less than 6 pages, create classic pagination */}
         {numPages < 6 &&
            numPages > 1 &&
            Array.from({ length: numPages }, (_, i) => (
               <Link
                  key={`pagination-number${i + 1}`}
                  to={`${uri}${i === 0 ? "" : i + 1}`}
                  className={`${
                     i === current - 1
                        ? "pagination__number pagination__number--current"
                        : "pagination__number"
                  }`}
               >
                  {i + 1}
               </Link>
            ))}

         {/* Adds last page pointer, when in between */}
         {numPages >= 6 &&
            currentPage !== numPages &&
            currentPage !== numPages - 1 &&
            currentPage !== numPages - 2 &&
            currentPage && (
               <>
                  <div className="pagination__spacer">
                     ...
                     <form
                        onSubmit={(e) => {
                           e.preventDefault();
                           navigate(`${uri}${pageInput}`);
                        }}
                     >
                        <input
                           className="pagination__input"
                           name="page-number"
                           id="pageNumber"
                           type="number"
                           placeholder="..."
                           value={pageInput}
                           onChange={(e) => {
                              setPageInput(e.target.value);
                           }}
                           min={1}
                           max={numPages}
                        />
                     </form>
                  </div>
                  <Link
                     key={`pagination-number-1`}
                     to={`${uri}${numPages}`}
                     className="pagination__number"
                  >
                     {numPages}
                  </Link>
               </>
            )}

         {/* Move to last page if not already last page */}
         {!isLast && (
            <Link
               to={`${uri}${nextPage}`}
               className="pagination__arrow pagination__arrow--next"
            ></Link>
         )}
      </div>
   );
};

export default Pagination;
