import "./section-title.scss";
import React from "react";

const SectionTitle = ({ title, icon }) => {
   return (
      <div className="section-title">
         {(!icon || icon === "default") && (
            <img
               src={require("assets/icons/heading-shape.svg").default}
               alt="Ikona nagłówka"
               className="section-title__icon"
            />
         )}
         {icon && icon !== "default" && (
            <img
               src={icon}
               alt="Ikona nagłówka"
               className="section-title__icon"
            />
         )}
         <h2 className="section-title__title">{title}</h2>
      </div>
   );
};

export default SectionTitle;
