import "./breadcrumbs.scss";

import React from "react";
import { Link } from "gatsby";

const Breadcrumbs = ({
  currentLocationLabel,
  prevLocationLabel,
  prevLocationLink,
}) => {
  return (
    <div className="breadcrumbs">
      <div className="container">
        <Link to="/" className="breadcrumbs__link breadcrumbs__link--home">
          Strona główna
        </Link>
        <span className="breadcrumbs__arrow">{">"}</span>
        {prevLocationLabel && (
          <Link to={prevLocationLink} className="breadcrumbs__link">
            {prevLocationLabel}
          </Link>
        )}
        {prevLocationLabel && <span className="breadcrumbs__arrow">{">"}</span>}
        <div className="breadcrumbs__link breadcrumbs__link--current">
          {currentLocationLabel}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
