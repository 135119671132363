import "./education-tile.scss";

import React from "react";
import { Link } from "gatsby";

const EducationTile = ({ title, thumbnail, link, date }) => {
  return (
    <div className="education-tile">
      <Link to={link}>
        <div className="education-tile__date">{date}</div>
        <div
          className="education-tile__thumbnail"
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
        <div className="education-tile__title">{title}</div>
      </Link>
    </div>
  );
};

export default EducationTile;
